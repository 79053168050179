import { SAVE, INSIGHT, UPDATE_PLAN_SVG } from '../common/imagePath';

export const middleBarFilterData = [
  {
    title: 'SCREENS',
    type: 'billboard,billboard_package',
    includeList: [],
    excludeList: [],
    listClassName: 'first_filter',
    name: 'billBoards',
    placeHolder: 'Search Billboard or Package Name',
  },
  {
    title: 'GEOGRAPHY',
    type: 'geography',
    includeList: [],
    excludeList: [],
    listClassName: 'second_filter',
    name: 'geography',
    placeHolder: 'Search State, City or Locality',
  },
  {
    title: 'POST CODE',
    type: 'geo_type',
    includeList: [],
    excludeList: [],
    listClassName: 'nineteenth_filter',
    name: 'postCode',
    placeHolder: 'Search State, City or Postal Code',
  },
  // {
  //   title: "MEDIA FORMAT",
  //   type: "media_format",
  //   includeList: [],
  //   excludeList: [],
  //   listClassName: "third_filter",
  //   name: "mediaFormat",
  //   placeHolder: "Search Media Format",
  // },
  {
    title: 'MEDIA OWNER',
    type: 'media_owner',
    includeList: [],
    excludeList: [],
    listClassName: 'fourth_filter',
    name: 'mediaOwner',
    placeHolder: 'Search Media Owner',
  },
  {
    title: 'POINTS OF INTEREST',
    type: 'category,brand,poiPackage',
    includeList: [],
    excludeList: [],
    listClassName: 'fifth_filter',
    name: 'interests',
    placeHolder: 'Search brands or place categories',
  },
  {
    title: 'PROGRAMMATIC AVAILABILITY',
    type: 'programmatic_availability',
    includeList: [],
    excludeList: [],
    listClassName: '22th_filter',
    name: 'programmaticAvailability',
    placeHolder: 'Search programmatic availability',
  },
  {
    title: 'SCREEN TYPE',
    type: 'screen_type',
    includeList: [],
    excludeList: [],
    listClassName: 'sixth_filter',
    name: 'screenType',
    placeHolder: 'Search Screen Type',
  },
  {
    title: 'SCREEN TYPE - CATEGORY',
    type: 'screen_type',
    includeList: [],
    excludeList: [],
    listClassName: '23th_filter',
    name: 'screenType',
    placeHolder: 'Search Screen Type',
  },
  {
    title: 'SCREEN TYPE - STATIC/DIGITAL',
    type: 'media_type_print_digital',
    includeList: [],
    excludeList: [],
    listClassName: '21th_filter',
    name: 'screenType_print_digital',
    placeHolder: 'Search screen type static or digital',
  },
  {
    title: 'SCREEN TYPE - LIGHTED',
    type: 'media_type_lighted',
    includeList: [],
    excludeList: [],
    listClassName: 'twentyFourth_filter',
    name: 'screenTypeLighted',
    placeHolder: '',
  },
  {
    title: 'SCREEN FORMAT',
    type: 'screen_format',
    includeList: [],
    excludeList: [],
    listClassName: 'seventh_filter',
    name: 'screenFormat',
    placeHolder: 'Search Screen Format',
  },
  {
    title: 'MULTI PANEL COUNT',
    type: 'multi_panel_count',
    includeList: [],
    excludeList: [],
    listClassName: 'twentyFifth_filter',
    name: 'multiPanelCount',
    placeHolder: 'Search Multi Panel Count',
  },
  {
    title: 'SCREEN PRODUCTS',
    type: 'PACKAGE',
    includeList: [],
    excludeList: [],
    listClassName: 'twentieth_filter',
    name: 'package',
    placeHolder: 'Search Screen Products',
  },
  {
    title: 'FACING DIRECTION',
    type: 'facing_direction',
    includeList: [],
    excludeList: [],
    listClassName: 'eighth_filter',
    name: 'facingDirection',
    placeHolder: 'Search Facing Direction',
  },
  {
    title: 'ORIENTATION',
    type: 'orientation',
    includeList: [],
    excludeList: [],
    listClassName: 'ninth_filter',
    name: 'orientation',
    placeHolder: 'Search Orientation',
  },
  {
    title: 'ASPECT RATIO',
    type: 'aspect_ratio',
    includeList: [],
    excludeList: [],
    listClassName: 'tenth_filter',
    name: 'aspectRatio',
    placeHolder: 'Search Aspect Ratio',
  },
  {
    title: 'ACCEPTED FORMATS',
    type: 'accepted_formats',
    includeList: [],
    excludeList: [],
    listClassName: 'eleventh_filter',
    name: 'acceptedFormats',
    placeHolder: '',
  },
  {
    title: 'SCREEN BOOKING TIME',
    type: 'screen_booking_time',
    includeList: [],
    excludeList: [],
    listClassName: 'twelfth_filter',
    name: 'screenBookingTime',
    placeHolder: 'Search Screen Booking Time',
  },
  {
    title: 'SCREEN OPERATING HOURS',
    type: 'screen_operating_hours',
    includeList: [],
    excludeList: [],
    listClassName: 'thirteenth_filter',
    name: 'screenOperatingHours',
    placeHolder: '',
  },
];

export const audienceFiltersList = [
  {
    title: 'AUDIENCES',
    // type: "brand,appcategory,iabcateogry,category,geo_behaviour,age,gender,online_brand,intent,events",
    type: 'brand,iabcateogry,category,geo_behaviour,mea,cip',
    includeList: [],
    excludeList: [],
    listClassName: 'thirteenth_filter',
    name: 'audiences',
    placeHolder: 'Search Audiences',
    countryId: [14],
  },
];

export const countrywiseFiltersList = {
  106: [
    {
      title: 'MEDIA VEHICLE',
      type: 'media_vehicle',
      includeList: [],
      excludeList: [],
      listClassName: 'forteenth_filter',
      name: 'mediaVehicle',
      placeHolder: 'Search Media Vehicle',
      status: 'true',
    },
    {
      title: 'MEDIA CATEGORY NAME',
      type: 'media_category_name',
      includeList: [],
      excludeList: [],
      listClassName: 'fifteenth_filter',
      name: 'mediaCategoryName',
      placeHolder: 'Search Media Category Name',
      status: 'true',
    },
    {
      title: 'MEDIA TYPE',
      type: 'media_type',
      includeList: [],
      excludeList: [],
      listClassName: 'sixteenth_filter',
      name: 'mediaType',
      placeHolder: 'Search Media Type',
      status: 'true',
    },
    {
      title: 'AIM SCORE',
      type: 'aim_score',
      includeList: [],
      excludeList: [],
      listClassName: 'seventeenth_filter',
      name: 'aimScore',
      placeHolder: 'Search Aims Score',
      status: 'true',
    },
  ],
};

export const middleBarBottomTabData = [
  {
    title: 'Update Plan',
    src: UPDATE_PLAN_SVG,
    id: 'update_plan',
  },
  {
    title: 'Insights',
    src: INSIGHT,
    id: 'insight',
  },
  {
    title: 'Save',
    src: SAVE,
    id: 'save',
  },
  // {
  //   title: "Export",
  //   src: EXPORT,
  //   id: "export",
  // },
  // {
  //   title: "Activate",
  //   src: ACTIVATE,
  //   id: "activate",
  // },
];

export const insightCheckboxData = [
  {
    label: 'Nearby POI',
    value: 'nearByPoi',
    id: 'nearByPoi',
    checked: false,
    defaultChecked: false,
  },
  {
    label: 'Journeys',
    value: 'journeys',
    id: 'journeys',
    checked: true,
    defaultChecked: true,
  },
  {
    label: 'Catchment',
    value: 'catchment',
    id: 'catchment',
    checked: false,
    defaultChecked: false,
  },
];

export const dougnutData = {
  labels: ['male', 'female', 'Others'],
  datasets: [
    {
      backgroundColor: ['#072164', '#396FC9', '#46CDC5'],
      data: [900, 700, 50],
    },
  ],
};

export const doughnutOptions = {
  title: {
    display: true,
  },
  legend: {
    display: true,
    position: 'right',
    labels: {
      usePointStyle: true,
    },
  },
  elements: {
    center: {
      text: '90%',
      color: '#FF6384',
      fontStyle: 'Arial',
      sidePadding: 20,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
};

export const pieChartOptions = {
  title: {
    display: true,
  },
  legend: {
    display: true,
    position: 'right',
    labels: {
      usePointStyle: true,
    },
  },
  elements: {
    center: {
      text: '90%',
      color: '#FF6384',
      fontStyle: 'Arial',
      sidePadding: 20,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
};

export const colorClass = ['primary', 'success', 'warning', 'danger', 'info'];

export const intialGeoData = [
  {
    id: 106,
    name: 'India',
    longitude: 82.58886,
    latitude: 22.546633,
    zoom: 3,
  },
  {
    id: 200,
    name: 'Singapore',
    longitude: 103.820556,
    latitude: 1.348376,
    zoom: 9,
  },
  {
    id: 134,
    name: 'Malaysia',
    longitude: 109.430669,
    latitude: 3.848045,
    zoom: 3.7,
  },
  {
    id: 14,
    name: 'Australia',
    longitude: 133.817201,
    latitude: -25.712759,
    zoom: 2.6,
  },
  {
    id: 107,
    name: 'Indonesia',
    longitude: 118.806032,
    latitude: -3.703943,
    zoom: 2.4,
  },
  {
    id: 172,
    name: 'Philippines',
    longitude: 122.205716,
    latitude: 11.994233,
    zoom: 4.8,
  },
  {
    id: 48,
    name: 'Hong Kong',
    longitude: 114.128342,
    latitude: 22.364069,
    zoom: 8.8,
  },
  {
    id: 221,
    name: 'Thailand',
    longitude: 101.358896,
    latitude: 13.45179,
    zoom: 4.6,
  },
  {
    id: 18,
    name: 'Bahrain',
    longitude: 50.561915,
    latitude: 26.070629,
    zoom: 9.3,
  },
  {
    id: 115,
    name: 'Japan',
    longitude: 139.219813,
    latitude: 37.426673,
    zoom: 3.3,
  },
  {
    id: 121,
    name: 'Kuwait',
    longitude: 47.527453,
    latitude: 29.309371,
    zoom: 7,
  },
  {
    id: 177,
    name: 'Qatar',
    longitude: 51.181838,
    latitude: 25.280103,
    zoom: 7.7,
  },
  {
    id: 165,
    name: 'Oman',
    longitude: 82.58886,
    latitude: 22.546633,
    zoom: 3,
  },
  {
    id: 195,
    name: 'Saudi Arabia',
    longitude: 44.978083,
    latitude: 24.079482,
    zoom: 3.5,
  },
  {
    id: 235,
    name: 'United Arab Emirates',
    longitude: 53.912805,
    latitude: 24.397934,
    zoom: 5.6,
  },
  {
    id: 245,
    name: 'Vietnam',
    longitude: 107.301843,
    latitude: 15.809445,
    zoom: 4.5,
  },
  {
    id: 157,
    name: 'New Zealand',
    longitude: 173.029707,
    latitude: -40.807074,
    zoom: 4.2,
  },
];

export const insightDataOrder = [
  'OOH',
  'GENDER',
  'AGE',
  'BRAND',
  'CATEGORY',
  'GEO_BEHAVIOUR',
  'DEVICE_MAKE',
  'DEVICE_CARRIER',
  'DEVICE_MODEL',
  'APP_NAME',
  'APP_CATEGORY',
  'IAB_CATEGORY',
  'EVENTS',
  'INTENT',
  'ONLINE_BRAND',
];

// -------------------- Start Audience filter data ----------------------

export const audienceFilterData = [
  { type: 'BRAND', name: 'brand' },
  { type: 'GEO_BEHAVIOUR', name: 'geoBehaviour' },
  // { type: "APPCATEGORY", name: "appCategory" },
  { type: 'CATEGORY', name: 'category' },
  { type: 'CUSTOM_AUDIENCE', name: 'customAudience' },
  // { type: "AGE", name: "age" },
  // { type: "GENDER", name: "gender" },
  // { type: "INTENT", name: "intent" },
  // { type: "ONLINE_BRAND", name: "online_brand" },
  // { type: "EVENTS", name: "events" }
];

export const audienceFilterDataLatest = [
  { type: 'BRAND', name: 'brand' },
  { type: 'GEO_BEHAVIOUR', name: 'geoBehaviour' },
  { type: 'APPCATEGORY', name: 'appCategory' },
  { type: 'CATEGORY', name: 'category' },
  { type: 'AGE', name: 'age' },
  { type: 'GENDER', name: 'gender' },
  { type: 'INTENT', name: 'intent' },
  { type: 'ONLINE_BRAND', name: 'online_brand' },
  { type: 'EVENTS', name: 'events' },
  { type: 'DEMOGRAPHICS', name: 'demographics' },
  { type: 'CUSTOM_AUDIENCE', name: 'customAudience' },
];

// -------------------- End Audience filter data ------------------------

export const myPlanTabs = [
  { id: 'oohPlan', name: 'OOH Plan' },
  // { id: 'ttdAdGroups', name: 'TTD Ad Groups' },
  { id: 'screenFallery', name: 'Screen Gallery' },
];
