import { put, takeLatest, call } from 'redux-saga/effects';
import plannerGetApiCaller from '../../getApiCaller';

const getEndPointUrlWithValue = (
  name,
  type,
  countryId,
  customerId,
  value,
  searchCurrPage,
  size
) => {
  if (
    name === 'billBoards' ||
    name === 'mediaFormat' ||
    name === 'mediaOwner' ||
    name === 'screenType' ||
    name === 'screenFormat' ||
    name === 'facingDirection' ||
    name === 'orientation' ||
    name === 'aspectRatio' ||
    name === 'acceptedFormats' ||
    name === 'screenOperatingHours' ||
    name === 'mediaVehicle' ||
    name === 'mediaCategoryName' ||
    name === 'mediaType' ||
    name === 'aimScore' ||
    name === 'programmaticAvailability' ||
    name === 'screenType_print_digital' ||
    name === 'screenTypeLighted' ||
    name === 'multiPanelCount' ||
    name === 'screenBookingTime'
  ) {
    return `ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&text=${value}&type=${type}`;
  }
  return `ooh/filter/audience?countryId=${countryId}&text=${value}&type=${type}`;
};

const getEndPointUrl = (name, type, countryId, customerId, currPage, size) => {
  if (
    name === 'billBoards' ||
    name === 'mediaFormat' ||
    name === 'mediaOwner' ||
    name === 'screenType' ||
    name === 'screenFormat' ||
    name === 'facingDirection' ||
    name === 'orientation' ||
    name === 'aspectRatio' ||
    name === 'acceptedFormats' ||
    name === 'screenOperatingHours' ||
    name === 'mediaVehicle' ||
    name === 'mediaCategoryName' ||
    name === 'mediaType' ||
    name === 'aimScore' ||
    name === 'postCode' ||
    name === 'programmaticAvailability' ||
    name === 'screenType_print_digital' ||
    name === 'screenTypeLighted' ||
    name === 'multiPanelCount' ||
    name === 'screenBookingTime'
  ) {
    return `ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=${type}`;
  } else if (name === 'geography') {
    if (
      +process.env.REACT_APP_SIGHTLINE_USE_OLDER_GEO_API_FOR_HONG_KONG &&
      countryId === 48
    ) {
      return `audience/filter?countryId=${countryId}&type=geography`;
    }
    return `ooh/filter/audience/geography?countryId=${countryId}`;
  } else if (name === 'package') {
    return `screeniq/customPlanner`;
  }
  return `ooh/filter/audience?countryId=${countryId}&type=${type}`;
};

const API_DATA = (payload) => {
  const { countryId, value, type, customerId, name } = payload;
  if (value) {
    return plannerGetApiCaller(
      `${getEndPointUrlWithValue(name, type, countryId, customerId, value)}`,
      'Get',
      true
    ).then((response) => {
      return response;
    });
  }
  return plannerGetApiCaller(
    `${getEndPointUrl(name, type, countryId, customerId)}`,
    'Get',
    true
  ).then((response) => {
    return response;
  });
};

export const FILTER_VALUES_SAGA = function* perform_getFilterValues() {
  yield takeLatest('FILTER_VALUES', function* (action) {
    yield put({ type: 'FILTER_VALUES_STARTED' });
    if (action.payload.unMount) {
      try {
        let status = true;
        const DATA = {
          status,
          id: action.payload.result,
        };
        yield put({
          type: 'FILTER_VALUES_SUCCESS',
          payload: {
            DATA,
            unMount: action.payload.unMount,
            postCodePackages: action.payload.postCodePackages,
          },
        });
      } catch (error) {
        yield put({
          type: 'FILTER_VALUES_FAILED',
          payload: error,
        });
      }
    } else if (action.payload.searchFlag) {
      try {
        const status = true;
        const DATA = {
          status,
          id: action.payload.result,
        };
        yield put({
          type: 'FILTER_VALUES_SUCCESS',
          payload: {
            DATA,
            type: action.payload.name,
            value: action.payload.value,
            searchFlag: action.payload.searchFlag,
            postCodePackages: action.payload.postCodePackages,
          },
        });
      } catch (error) {
        yield put({
          type: 'FILTER_VALUES_FAILED',
          payload: error,
        });
      }
    } else {
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({
          type: 'FILTER_VALUES_SUCCESS',
          payload: {
            DATA,
            type: action.payload.name,
            value: action.payload.value,
            searchFlag: action.payload.searchFlag,
            postCodePackages: action.payload.postCodePackages,
          },
        });
      } catch (error) {
        yield put({
          type: 'FILTER_VALUES_FAILED',
          payload: error,
        });
      }
    }
  });
};
