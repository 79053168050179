import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';
import authReducer from 'auth/authReducer';
import accountReducer from 'profile/accountReducer';
import builderReducer from 'audience/builder/redux/reducer';
import oohReducer from 'ooh/Planner/redux/reducers';
import reportReducer from 'attribution/ReportNew/reportReducer';
import attrInsightReducer from 'attribution/ReportNew/attributionInsight/insightReducer';
import CreateCampaignReducer from 'campaign/redux/reducers';
import authorization from 'layouts/Landing/newAuthReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import customAudienceReducer from './customAudience/customAudienceReducer';
import oohModuleReducer from './ooh/oohReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  form: formReducer, // ← redux-form
  createCampaign: CreateCampaignReducer,
  account: accountReducer,
  builder: builderReducer,
  ooh: oohReducer,
  report: reportReducer,
  attrInsight: attrInsightReducer,
  authorization: authorization,
  audience: customAudienceReducer,
  oohModule: oohModuleReducer,
});

const config = {
  key: 'root',
  storage,
};

const reducer = persistReducer(config, rootReducer);

export default reducer;
