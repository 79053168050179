export const TTD = 'ttd';
export const DOOH = 'dooh';
export const OOH = 'ooh';
export const PDOH = 'PDOH';
export const DIGITAL = 'DIGITAL';
export const FOOTFALL = 'FOOTFALL';
export const NO_FOOTFALL = 'NO_FOOTFALL';
export const HIVESTACK = 'hivestack';
export const FACEBOOK = 'facebook';
export const AMOBEE = 'amobee';
export const TWITTER = 'twitter';
export const GOOGLEMP = 'gmp';
export const PlanDeleteMessage = 'Plan deleted successfully.';
export const SCREEN_FORMAT = 'screenFormat';
export const PACKAGE = 'PACKAGE';
export const isTTD = 'isTTD';
export const isHiveStack = 'isHiveStack';
export const OBJECT = 'object';
export const POSTCODE = 'PostCode';
export const PACKAGES = 'packages';
export const GCS = 'GCS';
export const GCS_CREDENTIAL = 'GCS Credential';
export const DESTINATION = 'destination';
export const WEBSITE = 'Website';
export const ADVERTISING = 'Advertising';
export const INITIAL = 'INITIAL';
export const PROGRESS = 'PROGRESS';
export const FAILED = 'FAILED';
export const SUCCESS = 'SUCCESS';
